<template>
  <svg class="vuestic-icon-clean-code" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.02 50.34"><defs></defs><title>overview_icon_4</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M38.23,16.17a10,10,0,1,0-17.67,6.42V47.5l7.33-5,8,5V22.58A10,10,0,0,0,38.23,16.17Z"/><path class="cls-2" d="M28.23,0a13.15,13.15,0,0,0-9.17,22.6V50.34l8.87-6,9.46,5.92V22.6A13.15,13.15,0,0,0,28.23,0ZM34.4,44.79l-6.54-4.08-5.8,4V24.79a13.11,13.11,0,0,0,12.33,0ZM28.23,23.33A10.17,10.17,0,1,1,38.4,13.17,10.18,10.18,0,0,1,28.23,23.33Z"/><path class="cls-2" d="M28.23,5.67a7.5,7.5,0,1,0,7.5,7.5A7.51,7.51,0,0,0,28.23,5.67Zm0,12a4.5,4.5,0,1,1,4.5-4.5A4.5,4.5,0,0,1,28.23,17.67Z"/><polygon class="cls-2" points="9.51 15.11 0 24.61 9.51 34.12 11.63 32 4.24 24.61 11.63 17.23 9.51 15.11"/><polygon class="cls-2" points="46.52 15.11 44.39 17.23 51.78 24.61 44.39 32 46.52 34.12 56.02 24.61 46.52 15.11"/></g></g></svg>
</template>

<script>
export default {
  name: 'vuestic-icon-clean-code'
}
</script>

<style lang="scss">
  .vuestic-icon-clean-code {
    display: inline-block;
    width: 56px;
    height: 50px;
    .cls-1 {
      fill: #4ae387;
    }
    .cls-2 {
      fill:#34495e;
    }
  }
</style>
