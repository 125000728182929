<template>
  <svg class="vuestic-icon-responsive" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.5 49"><defs></defs><title>overview_icon_3</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><polygon class="cls-1" points="37 26 37 7 11 7 11 18 3 18 3 46 11 46 15 46 30 46 37 46 45 46 45 26 37 26"/><path class="cls-2" d="M40,19V0H8V11H0V49H47.5V19ZM3,46V14H8V46Zm34,0H11V3H37Zm7.5,0H40V22h4.5Z"/><circle class="cls-2" cx="24" cy="41" r="2.67"/></g></g></svg>
</template>

<script>
export default {
  name: 'vuestic-icon-responsive'
}
</script>

<style lang="scss">
  .vuestic-icon-responsive {
    display: inline-block;
    width: 47.5px;
    height: 49px;
    .cls-1 {
      fill: #4ae387;
    }
    .cls-2 {
      fill:#34495e;
    }
  }
</style>
