<template>
  <svg class="vuestic-icon-rich" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.99 55"><defs></defs><title>overview_icon_6</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><rect class="cls-1" x="10.31" y="30.5" width="37.33" height="23"/><path class="cls-2" d="M57,41.18l-7.85-16V24H8.81v1.11L0,41.11l2.63,1.45L8.81,31.33V55H49.15V32L54.3,42.5ZM46.15,52H11.81V27H46.15Z"/><polygon class="cls-2" points="35.3 1.8 32.9 0 28.12 6.39 26.16 4.63 24.16 6.87 28.56 10.8 35.3 1.8"/><polygon class="cls-2" points="22.3 12.46 19.9 10.67 15.12 17.05 13.16 15.3 11.16 17.54 15.56 21.47 22.3 12.46"/><polygon class="cls-2" points="38.89 21.14 45.64 12.13 43.23 10.33 38.45 16.72 36.49 14.97 34.49 17.2 38.89 21.14"/></g></g></svg>
</template>

<script>
export default {
  name: 'vuestic-icon-rich'
}
</script>

<style lang="scss">
  .vuestic-icon-rich {
    display: inline-block;
    width: 57px;
    height: 55px;
    .cls-1 {
      fill: #4ae387;
    }
    .cls-2 {
      fill:#34495e;
    }
  }
</style>
